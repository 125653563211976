<template>
        <defaultSec :returnState="true">
            <template #title>
                <div  class="default-header" style="display: flex;justify-content: space-between">
                    <h3 v-if="editAble" style="cursor: pointer" @click="returnPage">< 工单详情</h3>
                    <h3 v-else style="cursor: pointer">工单详情</h3>
                    <div>
                        <el-popover
                            v-if="workOrderInfo.workOrderFlowList.length>0"
                            trigger="hover"
                            placement="bottom"
                            width="400">
                            <el-alert style="margin-bottom: 20px" type="success" :closable="false">
                                <template #title>
                                    <i class="el-icon-s-flag"></i> 操作日志
                                </template>
                            </el-alert>
                            <div style="height: 350px;overflow-y: scroll">
                                <el-timeline size="small">
                                    <el-timeline-item
                                        v-for="(item, index) in workOrderInfo.workOrderFlowList"
                                        :key="index"
                                        color="#0bbd87"
                                        :timestamp="item.CREATE_TIME">
                                        <h4>{{item.action}}</h4>
                                        <p v-if="item.HANDLE_TEXT" style="color: #999999;font-size: 12px">备注：{{item.HANDLE_TEXT}}</p>
                                        <el-tag :type="item.EVENT_TYPE==1?'success':(item.EVENT_TYPE==4?'danger':(item.EVENT_TYPE==5?'warning':''))">{{item.EVENT_NAME}}</el-tag>
                                    </el-timeline-item>
                                </el-timeline>

                            </div>
                            <el-button type="warning" style="padding: 5px 10px" slot="reference" icon="el-icon-notebook-1">操作日志</el-button>
                        </el-popover>
                        <el-divider direction="vertical"></el-divider>

                        <template v-if="editAble">
                            <template v-if="workOrderInfo.workOrderStatus!=10&&workOrderInfo.workOrderStatus!=0">
                                <el-button style="float: right; padding: 5px 10px" type="danger" @click="addWorkOrderSubmit">保存工单</el-button>
                                <el-button v-if="workOrderNo!=0" style="float: right; padding: 5px 10px;margin: 0 5px" type="primary" @click="handleWorkOrder(0)">关闭工单</el-button>
                            </template>
                            </template>
                        <template v-else>
                            <el-button style="float: right; padding: 5px 10px" type="danger" @click="handleWorkOrder(1)">处理工单</el-button>
                            <el-button style="float: right; padding: 5px 10px;margin: 0 5px" type="primary" @click="handleWorkOrder(2)">指派工单</el-button>
<!--                            <el-button style="float: right; padding: 5px 10px;" type="warning" @click="handleWorkOrder(3)">退回工单</el-button>-->
                        </template>
                    </div>

                </div>

            </template>
            <div class="work-order-detail-info" v-loading="loading">
                <el-tabs type="border-card">
                    <el-tab-pane label="工单信息">
                        <el-alert style="margin: 10px 0"
                            title="关联信息"
                            type="success"
                            :closable="false">
                        </el-alert>
                        <el-descriptions class="margin-top" :label-style="labelStyle" :column="columnsCount" size="small" border>

                            <el-descriptions-item label="购买渠道">
                                <template slot="label">
                                    <span style="color: #e50011">*</span> 购买渠道
                                </template>
                                <el-radio-group :disabled="!editAble||workOrderNo!=0?true:false" size="mini" v-model="workOrderInfo.orderOnline">
                                    <el-radio :label="1">线上</el-radio>
                                    <el-radio :label="2">线下</el-radio>
                                </el-radio-group>
                            </el-descriptions-item>
                            <el-descriptions-item label="联系人">
                                <el-input :disabled="!editAble?true:false" size="small" v-model="workOrderInfo.contactName" placeholder="请输入联系人" ></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item label="联系电话">
                                <el-input :disabled="!editAble?true:false" size="small" v-model="workOrderInfo.contactPhone" placeholder="请输入联系电话" ></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item label="联系地址">
                                <el-input :disabled="!editAble?true:false" size="small" v-model="workOrderInfo.contactAddr" placeholder="请输入联系地址" ></el-input>
                            </el-descriptions-item>
                            <template v-if="workOrderInfo.orderOnline==2">
                                <el-descriptions-item label="线下门店">
                                    <el-input :disabled="!editAble?true:false" size="small" v-model="workOrderInfo.offlineShopName" placeholder="请输入线下门店信息" ></el-input>
                                </el-descriptions-item>
                                <el-descriptions-item label="线下单号">
                                    <el-input :disabled="!editAble?true:false" size="small" v-model="workOrderInfo.offlineOrderNo" placeholder="请输入线下订单号" ></el-input>
                                </el-descriptions-item>
                            </template>
                            <template v-else-if="workOrderInfo.orderOnline==1">
                                <el-descriptions-item label="工单来源">
                                    <el-select :disabled="!editAble?true:false" size="small" style="width: 100%" v-model="workOrderInfo.orderSource" placeholder="请选择">
                                        <el-option
                                            v-for="item in orderSourceList"
                                            :key="item.key"
                                            :label="item.name"
                                            :value="item.key">
                                        </el-option>
                                    </el-select>
                                </el-descriptions-item>
                                <el-descriptions-item label="店铺">
                                    {{workOrderInfo.onlineShopName}}
                                </el-descriptions-item>
                                <el-descriptions-item label="外部单号">
                                    <el-input :disabled="!editAble?true:false" size="small" v-model="workOrderInfo.someID" placeholder="请输入外部单号的 订单号/售后单号" >
                                        <el-button  v-if="editAble&&workOrderInfo.workOrderStatus!=10" size="small" slot="append" type="primary" @click="getOrder">获取订单明细</el-button>
                                    </el-input>
                                </el-descriptions-item>
                                <el-descriptions-item label="外部单号类型">
                                <span style="color: #209e91">
                                    {{workOrderInfo.relateType?relateTypeList[workOrderInfo.relateType].title:'无关联'}}
                                </span>
                                </el-descriptions-item>
                            </template>

                        </el-descriptions>
                        <el-alert style="margin: 20px 0 10px 0"
                                  title="基本信息"
                                  type="success"
                                  :closable="false">
                        </el-alert>
                        <el-descriptions class="margin-top" :label-style="labelStyle" :column="columnsCount" size="small" border>
                            <el-descriptions-item label="售后类型">
                                <template slot="label">
                                    <span style="color: #e50011">*</span> 售后类型
                                </template>
                                <el-select :disabled="!editAble?true:false" size="small" @change="(val)=>handleWorkTypeChange(val,'workType')" style="width: 100%" value-key="workOrderTypeCode" v-model="workOrderInfo.workOrderType" placeholder="请选择">
                                    <el-option
                                        v-for="item in tabList"
                                        :key="item.workOrderTypeCode"
                                        :label="item.workOrderTypeName"
                                        :value="item">
                                    </el-option>
                                </el-select>
                            </el-descriptions-item>
                            <el-descriptions-item label="处理人">
                                <template slot="label">
                                    <span style="color: #e50011">*</span> 处理人
                                </template>
                                <el-select filterable :disabled="!editAble?true:false" size="small" @change="(val)=>handleWorkTypeChange(val,'handleUserId')" style="width: 100%" v-model="workOrderInfo.handleUserId" placeholder="请选择">
                                    <el-option
                                        v-for="item in userList"
                                        :key="item.USER_ID"
                                        :label="item.USER_NAME"
                                        :value="item.USER_ID">
                                    </el-option>
                                </el-select>
                                <span style="color: #e50011;font-size: 10px;">* 若是无需处理的工单，处理人选择「自己」，然后关闭工单即可</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span style="color: #e50011">*</span> 售后问题描述
                                </template>
                                <el-input type="textarea" :disabled="!editAble?true:false" size="small" v-model="workOrderInfo.workOrderDesc" placeholder="请输入售后问题描述" />
                            </el-descriptions-item>
                            <el-descriptions-item label="工单编号">
                                {{workOrderInfo.workOrderNo}}
                            </el-descriptions-item>
                            <el-descriptions-item label="创建时间">
                                {{workOrderInfo.createTime}}
                            </el-descriptions-item>
                            <el-descriptions-item label="创建人">
                                {{workOrderInfo.createUserId}}
                            </el-descriptions-item>
                            <el-descriptions-item  label="图片">
                                <div style="display: flex;align-items: center" v-if="editAble">
                                    <div @paste="pasteImgFun" class="paste-wrap">在此处粘贴</div>
                                    <span style="margin: 0 20px">或 </span>
                                    <el-upload
                                        multiple
                                        class="upload-demo"
                                        :action="''"
                                        :httpRequest="(file)=>uploadFile(file,'order')"
                                        :on-change="handlePicChange"
                                        :file-list="fileList">
                                        <el-button size="small" type="primary">本地上传</el-button>
                                    </el-upload>
                                </div>

                                <el-image
                                    v-for="item in workOrderInfo.picList||[]"
                                    :src="item" style="margin: 10px;width: 80px;height: 80px;"
                                    :preview-src-list="[item]">
                                </el-image>

                            </el-descriptions-item>
                        </el-descriptions>
                        <el-alert style="margin: 20px 0 10px 0"
                                  title="处理结果"
                                  type="success"
                                  :closable="false">
                        </el-alert>
                        <el-descriptions class="margin-top" :label-style="labelStyle" :column="columnsCount" size="small" border>
                            <el-descriptions-item label="工单状态">
                                <span :style="{color: workOrderInfo.workOrderStatus==0?'#999999':(workOrderInfo.workOrderStatus==1?'#dfb81d':(workOrderInfo.workOrderStatus==10?'green':'#e85656'))}">● {{workOrderInfo.workOrderStatusName}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item label="责任部门">
                                {{workOrderInfo.responsibleDeptDingdingId}}
                            </el-descriptions-item>
                            <el-descriptions-item label="处理结果">
                                {{workOrderInfo.workOrderResult}}
                            </el-descriptions-item>
                            <el-descriptions-item label="理赔金额">
                                {{workOrderInfo.compensationAmount}}
                            </el-descriptions-item>
                            <el-descriptions-item label="关闭工单原因">
                                {{workOrderInfo.workOrderResultDesc}}
                            </el-descriptions-item>
                            <el-descriptions-item label="关闭时间">
                                {{workOrderInfo.closeTime}}
                            </el-descriptions-item>
                            <el-descriptions-item label="处理日志">
                                <div style="height: 350px;overflow-y: scroll">
                                    <el-timeline size="small">
                                        <el-timeline-item
                                            v-for="(item, index) in workOrderInfo.workOrderFlowList"
                                            :key="index"
                                            color="#0bbd87"
                                            :timestamp="item.CREATE_TIME">
                                            <h5 style="margin: 10px 0">{{item.action}}</h5>
                                            <p v-if="item.HANDLE_TEXT" style="color: #999999;font-size: 12px">备注：{{item.HANDLE_TEXT}}</p>
                                            <el-tag size="mini" :type="item.EVENT_TYPE==1?'success':(item.EVENT_TYPE==4?'danger':(item.EVENT_TYPE==5?'warning':''))">{{item.EVENT_NAME}}</el-tag>
                                        </el-timeline-item>
                                    </el-timeline>

                                </div>
                            </el-descriptions-item>
                        </el-descriptions>
                        <template v-if="workOrderInfo.workOrderProductList.length>0">
                            <el-alert style="margin: 20px 0 10px 0"
                                      title="售后商品 （* 已选中的是需要售后的商品）"
                                      type="success"
                                      :closable="false">
                            </el-alert>
                            <el-table ref="salesProTable" row-key="barCode" border :data="workOrderInfo.workOrderProductList"
                                      style="width: 100%"
                                      size="mini"
                                      @selection-change="handleSelectionChange"
                            >
                                <el-table-column
                                    :selectable="()=>editAble"
                                    type="selection"
                                    width="40">
                                </el-table-column>
                                <el-table-column type="index" width="50" label="序号"></el-table-column>
                                <el-table-column prop="barCode" label="69码"></el-table-column>
                                <el-table-column prop="productName" label="商品名称"></el-table-column>
                                <el-table-column prop="factoryName" label="仓库"></el-table-column>
                                <el-table-column prop="pici" label="批次"></el-table-column>
                                <el-table-column label="物流">
                                    <template slot-scope="scope">
                                        {{scope.row.logisticName}}
                                        {{scope.row.wayNo?'('+scope.row.wayNo+'）':''}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="workOrderDesc" label="图片">
                                    <template slot-scope="scope">
                                        <el-image
                                            v-for="item in scope.row.picList||[]"
                                            :src="item" style="width: 80px;height: 80px;"
                                            :preview-src-list="[item]">
                                        </el-image>
                                    </template>
                                </el-table-column>
                                <el-table-column width="250" v-if="editAble" prop="workOrderDesc" label="操作">
                                    <template slot-scope="scope">
                                        <div style="display: flex;align-items: center">
                                            <div @paste="(event)=>pasteImgFun(event,scope.row)" class="paste-wrap">在此处粘贴</div>

                                            <span style="margin: 0 10px">或 </span>
                                            <el-upload
                                                :show-file-list="false"
                                                multiple
                                                class="upload-demo"
                                                :action="''"
                                                :httpRequest="(file)=>uploadFile(file,scope.row)"
                                                :on-change="handlePicChange"
                                                :file-list="fileList">
                                                <el-button size="mini" plain type="primary">本地上传</el-button>
                                            </el-upload>
                                        </div>

                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>


                    </el-tab-pane>
                    <el-tab-pane label="订单信息">
                        <template v-if="Object.keys(orderDetailInfo).length > 0">
                            <el-alert style="margin: 20px 0 10px 0"
                                      title="基本信息"
                                      type="success"
                                      :closable="false">
                            </el-alert>
                            <el-descriptions class="margin-top" :label-style="labelStyle" :column="columnsCount" size="small" border>
                                <el-descriptions-item label="店铺">
                                    {{orderDetailInfo.shopName}}
                                </el-descriptions-item>
                                <el-descriptions-item label="微信主单号">
                                    {{orderDetailInfo.orderMainNo}}
                                </el-descriptions-item>
                                <el-descriptions-item label="子单号/外部订单号">
                                    {{orderDetailInfo.orderNo}}
                                </el-descriptions-item>
                                <el-descriptions-item label="订单状态">
                                    {{orderDetailInfo.orderStatusName}}
                                </el-descriptions-item>
                                <el-descriptions-item label="订单类型">
                                    {{orderDetailInfo.orderTypeName}}
                                </el-descriptions-item>
                                <el-descriptions-item label="订单分类">
                                    {{orderDetailInfo.orderSonTypeName}}
                                </el-descriptions-item>
                                <el-descriptions-item label="冷链/常温">
                                    {{orderDetailInfo.orderIsCwName}}
                                </el-descriptions-item>
                                <el-descriptions-item label="是否流转">
                                    {{orderDetailInfo.orderFlowSignName}}
                                </el-descriptions-item>
                                <el-descriptions-item label="流转异常原因">
                                    {{orderDetailInfo.flowErroMessage}}
                                </el-descriptions-item>
                                <el-descriptions-item label="是否挂起">
                                    {{orderDetailInfo.isPending == 1 ? '是' : '否'}}
                                </el-descriptions-item>
                                <el-descriptions-item label="微信订单来源">
                                    {{orderDetailInfo.orderSourceName}}
                                </el-descriptions-item>
                                <el-descriptions-item label="微信来源主单号">
                                    {{orderDetailInfo.sourceOrderMainNo}}
                                </el-descriptions-item>
                                <el-descriptions-item label="微信订单分类">
                                    {{orderDetailInfo.orderClassName}}
                                </el-descriptions-item>
                                <el-descriptions-item label="购买人昵称">
                                    <p v-if="isWeixinOrder && orderDetailInfo.buyerId != 'csms-manual-order-creator'" @click="toBuyer(orderDetailInfo.buyerId)" style="text-decoration:underline;color:#0000ff;">{{orderDetailInfo.buyerName}}</p>
                                    <p v-if="!(isWeixinOrder && orderDetailInfo.buyerId != 'csms-manual-order-creator')">{{orderDetailInfo.buyerName}}</p>
                                </el-descriptions-item>
                                <el-descriptions-item label="支付时间">
                                    {{orderDetailInfo.payTime}}
                                </el-descriptions-item>
                                <el-descriptions-item label="备注">
                                    {{orderDetailInfo.remark}}
                                </el-descriptions-item>
                            </el-descriptions>
                            <el-alert style="margin: 20px 0 10px 0"
                                      title="支付信息"
                                      type="success"
                                      :closable="false">
                            </el-alert>
                            <el-descriptions class="margin-top" :label-style="labelStyle" :column="columnsCount" size="small" border>
                                <el-descriptions-item label="订单金额">
                                    {{orderDetailInfo.orderAmount}}
                                </el-descriptions-item>
                                <el-descriptions-item label="运费">
                                    {{orderDetailInfo.freight}}
                                </el-descriptions-item>
                                <el-descriptions-item v-if="this.isWeixinOrder" label="现金支付">
                                    {{orderDetailInfo.cashAmount}}
                                </el-descriptions-item>
                                <el-descriptions-item v-if="!this.isWeixinOrder" label="支付金额">
                                    {{orderDetailInfo.payAmount}}
                                </el-descriptions-item>
                                <el-descriptions-item label="优惠券">
                                    {{orderDetailInfo.orderCouponAmount}}
                                </el-descriptions-item>
                                <el-descriptions-item label="使用点数" :span="3">
                                    {{orderDetailInfo.specialCardPoint}}
                                </el-descriptions-item>
                                <el-descriptions-item label="余额支付总额" :span="3">
                                    {{orderDetailInfo.balanceAmount}}
                                </el-descriptions-item>
                                <el-descriptions-item label="余额储值部分">
                                    {{orderDetailInfo.balanceStoreAmount}}
                                </el-descriptions-item>
                                <el-descriptions-item label="余额赠送部分">
                                    {{orderDetailInfo.balanceFreeAmount}}
                                </el-descriptions-item>
                                <el-descriptions-item label="余额礼品卡部分">
                                    {{orderDetailInfo.balanceGiftAmount}}
                                </el-descriptions-item>
                                <el-descriptions-item label="微信交易号">
                                    {{orderDetailInfo.wechatPaySerialNo}}
                                </el-descriptions-item>
                                <el-descriptions-item label="商户单号">
                                    {{orderDetailInfo.wechatPayTradeNo}}
                                </el-descriptions-item>
                            </el-descriptions>
                            <el-alert style="margin: 20px 0 10px 0"
                                      title="配送信息"
                                      type="success"
                                      :closable="false">
                            </el-alert>
                            <el-descriptions class="margin-top" :label-style="labelStyle" :column="columnsCount" size="small" border>
                                <el-descriptions-item label="提货日期">
                                    {{orderDetailInfo.pickupDate}}
                                </el-descriptions-item>
                                <el-descriptions-item label="配送日期">
                                    {{orderDetailInfo.deliverDate}}
                                </el-descriptions-item>
                                <el-descriptions-item label="发货仓">
                                    {{orderDetailInfo.storeName}}
                                </el-descriptions-item>
                                <el-descriptions-item label="收货人">
                                    {{orderDetailInfo.consigneeName}}
                                </el-descriptions-item>
                                <el-descriptions-item label="收货电话">
                                    {{orderDetailInfo.consigneePhone}}
                                </el-descriptions-item>
                                <el-descriptions-item label="详细地址">
                                    {{orderDetailInfo.consigneeAddrEntire}}
                                </el-descriptions-item>
                                <el-descriptions-item label="物流公司">
                                    {{orderDetailInfo.wayName}}
                                </el-descriptions-item>
                                <el-descriptions-item label="物流单号">
                                    {{orderDetailInfo.wayNo}}
                                </el-descriptions-item>
                                <el-descriptions-item v-if="orderDetailInfo.wayNo != null && orderDetailInfo.wayNo != ''" label="物流信息">
                                    <el-timeline>
                                        <el-timeline-item
                                            v-for="(activity, index) in routers"
                                            :key="index"
                                            :timestamp="activity.time">
                                            {{activity.context}}
                                        </el-timeline-item>
                                    </el-timeline>
                                </el-descriptions-item>
                            </el-descriptions>
                            <el-alert style="margin: 20px 0 10px 0"
                                      title="退款记录"
                                      type="success"
                                      :closable="false">
                            </el-alert>
                            <el-table size="mini" :data="orderDetailInfo.orderRefundDetails" style="width: 100%">
                                <el-table-column prop="refundNo" label="退款单号" width="180"></el-table-column>
                                <el-table-column prop="refundType" label="退款类型" width="180"></el-table-column>
                                <el-table-column prop="afterSaleType" label="售后类型"></el-table-column>
                                <el-table-column prop="refundAmountCheck" label="退款金额"></el-table-column>
                                <el-table-column prop="refundStatus" label="状态"></el-table-column>
                                <el-table-column prop="refundReason" label="原因"></el-table-column>
                                <el-table-column prop="createId" label="操作人"></el-table-column>
                                <el-table-column prop="createTime" label="申请时间"></el-table-column>
                            </el-table>
                        </template>
                        <el-empty v-else :image-size="200" description="暂无订单信息"></el-empty>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <el-dialog
                width="80%"
                :title="handleTitle"
                :visible="addWorkOrderVisible"
                :before-close="handleClose">
                <el-form ref="form" :model="formData" label-width="90px" class="draw-box">
                    <template v-if="handleWorkType == 0">
                        <el-form-item label="责任部门" required>
                            <el-select filterable style="width: 100%" v-model="formData.dingDeptId" placeholder="请选择">
                                <el-option
                                    v-for="item in deptList"
                                    :key="item.key"
                                    :label="item.name"
                                    :value="item.key">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="理赔金额">
                            <el-input v-model="formData.compensationAmount" placeholder="请输入理赔金额" />
                        </el-form-item>
                        <el-form-item label="处理结果" required>
                            <el-select style="width: 100%" v-model="formData.workOrderResult" placeholder="请选择">
                                <el-option
                                    v-for="item in workResultList"
                                    :key="item.key"
                                    :label="item.name"
                                    :value="item.key">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </template>
                    <el-form-item v-if="handleWorkType==2" label="转给" required>
                        <el-select filterable style="width: 100%" v-model="formData.toUserId" placeholder="请选择要指派的处理人">
                            <el-option
                                v-for="item in userList"
                                :key="item.USER_ID"
                                :label="item.USER_NAME"
                                :value="item.USER_ID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="handleList[handleWorkType]?handleList[handleWorkType].textLabel:'备注'" required>
                        <el-input type="textarea" v-model="formData.text" placeholder="请输入" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleWorkOrderSubmit">立即保存</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    export default {
        data() {
            return {
                loading:false,
                resourceUrl: process.env.VUE_APP_API_PATH,
                orderDetailInfo:{},
                workOrderInfo:{
                    workOrderProductList:[],
                    workOrderFlowList:[],
                },
                formData:{},
                workOrderNo: this.$route.params.id,
                userList:[],
                deptList:[],
                tabList:[],
                fileList:[],
                workOrderProductList:[],
                isWeixinOrder: true,
                editAble:this.$route.query.editAble?true:false,
                addWorkOrderVisible:false,
                handleWorkType:'',
                handleList:[
                    {key:0,title:'关闭工单',apiUrl:'lechun-csms/workOrder/closeWorkOrder',textLabel:'处理结果描述'},
                    {key:1,title:'处理工单',apiUrl:'lechun-csms/workOrder/handleWorkOrder',textLabel:'如何处理'},
                    {key:2,title:'指派工单',apiUrl:'lechun-csms/workOrder/forwardWorkOrder',textLabel:'指派原因'},
                    {key:3,title:'退回工单',apiUrl:'lechun-csms/workOrder/returnWorkOrder',textLabel:'退回原因'},
                ],
                //关联类型：0无关联，1订单，2售后单，3微信客户
                relateTypeList:[
                    {key:0,title:'无关联'},
                    {key:1,title:'关联订单'},
                    {key:2,title:'关联售后单'},
                    {key:3,title:'关联微信用户'},
                ],
                handleTitle:'工单',
                logList: [],
                multipleSelProList: [],
                workResultList: [],
                orderSourceList: [],
                pasteImg:"",
                columnsCount:2,
                clientWidth:document.body.clientWidth,
                labelStyle:{
                    width: '100px',
                }

            }
        },
        components:{
            defaultSec,
        },
        mounted(){
            console.log(this.$route)
            if(this.clientWidth<500){
                this.columnsCount = 1
            }else{
                this.columnsCount = 2
            }
            window.onresize = ()=>{
                console.log(5)
                this.clientWidth =document.body.clientWidth
                if(this.clientWidth<500){
                    this.columnsCount = 1
                }else{
                    this.columnsCount = 2
                }
            }
            if(this.$route.params.id!=0){
                this.loading = true
                this.initList()
            }else{

            }
            this.initUserList()
            this.initWorkTypeList()
            this.initOrderSourceList()

        },
        methods: {
            initList(from) {
                let apiUrl = ''
                let param = {}
                if(from=='getOrder'){
                    apiUrl = 'lechun-csms/workOrder/getWorkOrderRelateInfo'
                    param = {someID:this.workOrderInfo.someID}
                }else{
                    apiUrl = 'lechun-csms/workOrder/workOrderDetail'
                    param = {workOrderNo:this.workOrderNo}
                }
                this.$apiGet(apiUrl,param).then(res => {
                    this.loading = false
                    this.orderDetailInfo = res.orderDetail||{};
                    if(from!='getOrder'){
                        let workOrderInfo = res.workOrderInfo||{}
                        // workOrderInfo.someID = workOrderInfo.relateType==1?workOrderInfo.orderNo:workOrderInfo.refundNo
                        workOrderInfo.workOrderType ={workOrderTypeCode:workOrderInfo.workOrderTypeCode,workOrderTypeName:workOrderInfo.workOrderTypeName}
                        workOrderInfo.workOrderProductList = workOrderInfo.workOrderProductList||[]
                        workOrderInfo.workOrderFlowList = workOrderInfo.workOrderFlowList||[];

                        this.workOrderInfo = workOrderInfo;

                        let selList = this.workOrderProductList.filter(item => item.isChosen==1)
                        this.$nextTick(() => {
                            if (selList.length > 0) {
                                selList.map(item=>{
                                    this.$refs.salesProTable.toggleRowSelection(item, true);
                                })
                            }
                        });
                    }else{
                        this.workOrderInfo = {...this.workOrderInfo,relateType:res.workOrderInfo?res.workOrderInfo.relateType:0};
                    }

                });
            },
            initUserList() {
                this.$apiGet('lechun-baseservice/user/getUserListForDorpbox',{}).then(res => {
                    console.log(res)
                    this.userList = res

                })
            },
            initDeptList() {
                this.$apiGet('lechun-csms/workOrder/getWorkOrderResponseDept',{}).then(res => {
                    console.log(res)
                    this.deptList = res

                })
            },
            initWorkTypeList() {
                this.$apiGet('lechun-csms/workOrder/workOrderTypeList',{}).then(res => {
                    console.log(res)
                    this.tabList = res
                })
            },
            initWorkOrderResultList() {
                this.$apiGet('lechun-csms/workOrder/getWorkOrderResultList',{}).then(res => {
                    console.log(res)
                    this.workResultList = res
                })
            },
            initOrderSourceList() {
                this.$apiGet('lechun-csms/workOrder/getWorkOrderSourceList',{}).then(res => {
                    console.log(res)
                    this.orderSourceList = res
                })
            },
            getOrder() {
                this.initList('getOrder')
            },
            toBuyer(id) {
                this.$router.push({name: 'userDetail', params: {id: id}})
            },
            handleWorkTypeChange(val,type){
                console.log(val,type)
                if(type=='workType'){
                    this.$set(this.workOrderInfo,'handleUserId',val.responsibleUserId)
                    this.$set(this.workOrderInfo,'workOrderTypeName',val.workOrderTypeName)
                }else{
                    this.$set(this.workOrderInfo,type,val)
                }
                console.log(this.workOrderInfo)
            },
            addWorkOrderSubmit(){
                let param = {...this.workOrderInfo}
                param.workOrderTypeCode = param.workOrderType?param.workOrderType.workOrderTypeCode:null
                param.workOrderProductList = (param.workOrderProductList||[]).map(item=>{
                    console.log(this.multipleSelProList.indexOf(item.barCode))
                    if(this.multipleSelProList.indexOf(item.barCode)>-1){
                        item.isChosen = 1
                    }else{
                        item.isChosen = 0
                    }
                    return item
                })
                console.log(11,param)
                this.$apiPost('lechun-csms/workOrder/saveWorkOrder',param).then(res => {
                    console.log(res)
                    this.$message({
                        message: '成功',
                        type: 'success',
                        onClose: () => {
                            this.$router.history.go(-1)
                            // this.$router.push({name: 'workOrderList'})

                        }
                    });
                })
            },
            handleClose(){
                this.addWorkOrderVisible = false;
                this.formData = {}
            },
            handleWorkOrder(from) {
                console.log(from,this.handleList[from])
                this.handleTitle = this.handleList[from].title
                this.handleWorkType = from
                if(from==0){
                    this.initWorkOrderResultList()
                    this.initDeptList()
                }
                this.addWorkOrderVisible = true;
            },
            handlePicChange(file,fileList) {
                console.log(file,fileList)
            },
            returnPage() {
                window.history.go(-1)
                // this.$router.push({
                //     name: 'workOrderList'
                // })
            },
            uploadFile(file,type) {
                console.log(234,file,type)
                let fileForm = new FormData();
                fileForm.append('file',file.file)
                this.$apiUpload('lechun-cms/file/uploadFile',fileForm).then(res => {
                    console.log(res)
                    if(type=='order'){
                        let picList =this.workOrderInfo.picList||[]
                        picList.push(res[0])
                        this.$set(this.workOrderInfo,'picList',picList)
                    }else{
                        let picList = type.picList||[]
                        picList.push(res[0])
                        type.picList = picList
                        console.log(picList)
                    }
                })
            },
            handleWorkOrderSubmit(){
                let param = {...this.formData}
                param.workOrderNo = this.workOrderNo
                let url = this.handleList[this.handleWorkType].apiUrl
                console.log('param',param)
                this.$apiGet(url,param).then(res => {
                    console.log(res)
                    this.$message({
                        message: '成功',
                        type: 'success'
                    });
                    this.handleClose()
                    this.initList()
                })
            },
            handleSelectionChange(val){
                console.log(val)
                this.multipleSelProList = val.map(item=>item.barCode)
            },
            pasteImgFun(event,proItem){
                console.log('paster',event,proItem)
                const clipboardData = event.clipboardData || window.clipboardData;

                if (clipboardData) {
                    console.log('clipboardData',clipboardData.getData('Text'))
                    for (let i = 0; i < clipboardData.items.length; i++) {
                        const item = clipboardData.items[i];
                        console.log('item',item)
                        if (item.kind === 'file' && item.type.startsWith('image/')) {
                            const file = item.getAsFile();
                            console.log('file',file)
                            this.uploadFile({file:file},proItem?proItem:'order')
                        }else if(item.kind==='string'){
                            item.getAsString((str)=>{
                                console.log(str)
                            })
                        }
                    }
                }
            }
        }
    }
</script>


<style lang="scss">
.work-order-detail-info {

    .box-card{
        margin-bottom: 20px;
    }
    .el-card__header {
        padding: 10px;
    }

    .el-card__body {
        padding: 0 10px 20px;
    }

    .popover-title {
        margin: 0 0 15px 0;
    }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 100%;
    }

    .el-tag {
        margin-right: 10px;
    }
    .el-divider__text {
        color: #209e91;
    }
    .paste-wrap{
        width: 100px;
        height: 80px;
        border: 1px dashed #209e91;
        text-align: center;
        line-height: 80px;
    }
}
</style>
